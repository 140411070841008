import React, { memo } from 'react'
import styled from 'styled-components'

const StyledBurger = styled.div`
  display: flex;
  flex-direction: column;
  width: 16px;
  margin: 0 auto;

  .line {
    display: block;
    width: 100%;
    height: 1px;
    background: white;
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Burger = () => (
  <StyledBurger mr={2}>
    <span className="line" />
    <span className="line" />
    <span className="line" />
  </StyledBurger>
)

export default memo(Burger)
