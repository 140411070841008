import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'

const GridItem = styled(Grid)`
  padding-top: 10px !important;
  padding-bottom: 10px !important;
`

const FormGridItem = ({ children, ...props }) => {
  return (
    <GridItem item {...props}>
      {children}
    </GridItem>
  )
}

FormGridItem.propTypes = {
  children: PropTypes.node.isRequired,
}

export default memo(FormGridItem)
