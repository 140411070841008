import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Img from 'gatsby-image'
import Drawer from '@material-ui/core/Drawer'

import breakpoints from 'helpers/breakpoints'

import Flex from 'components/atoms/Flex'

import FollowButtons from 'components/molecules/social/FollowButtons'

const StyledDrawer = styled(Drawer)`
  left: 100% !important;

  .MuiDrawer-paper {
    left: 100%;
    background: ${props => props.theme.colors.greyLight};
    ${breakpoints.greaterThan('xl')`
      left: 50%;
  `}
  }
`

const StyledImage = styled(Img)`
  position: absolute !important;
  top: 14%;
  right: 20%;
  bottom: 14%;
  left: 20%;
  width: auto;
  height: auto;
`

const FollowContainer = styled.div`
  position: absolute;
  bottom: 5%;
  right: 10%;
  transform: translate3d(50%, 0, 0);
  a {
    color: ${props => props.theme.colors.textDefault};
  }
`

const SidebarContent = ({ open, hideSidebar, navImage }) => {
  return (
    <StyledDrawer
      open={open}
      anchor="right"
      onClose={hideSidebar}
      className="navDrawer"
      elevation={0}
      ModalProps={{
        keepMounted: true,
        disablePortal: true,
        hideBackdrop: true,
        disableBackdropClick: true,
      }}
    >
      <Flex full center>
        <StyledImage fluid={navImage.fluid} />
      </Flex>
      {/* <FollowContainer>
        <FollowButtons variant="dark" />
      </FollowContainer> */}
    </StyledDrawer>
  )
}

SidebarContent.propTypes = {
  open: PropTypes.bool,
  hideSidebar: PropTypes.func.isRequired,
  navImage: PropTypes.object.isRequired,
}
export default SidebarContent
