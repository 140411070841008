const mainNav = [
  {
    slug: 'home',
    label: 'Home',
  },
  {
    slug: 'services',
    label: 'Services',
  },
  {
    slug: 'collections',
    label: 'Collections',
  },
  {
    slug: 'about-us',
    label: 'About Us',
  },
  {
    slug: 'contact',
    label: 'Contact',
  },
]

export default mainNav
