import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebookF,
  faTwitter,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons'

import Flex from 'components/atoms/Flex'
import ExternalButtonLink from 'components/atoms/buttons/ExternalButtonLink'

const FollowButtons = ({ variant, direction, borderClasses, btnProps }) => {
  let variantClasses = 'outline-light hover-white-bg'
  if (variant == 'dark') {
    variantClasses = 'outline-dark'
  }

  return (
    <Flex flexDirection={direction} center>
      <ExternalButtonLink
        href="http://www.facebook.com"
        className={`lg round ${borderClasses} ${variantClasses}`}
        variant="outlined"
        {...btnProps}
      >
        <FontAwesomeIcon icon={faFacebookF} />
      </ExternalButtonLink>

      <ExternalButtonLink
        href="http://www.facebook.com"
        className={`lg round ${borderClasses} ${variantClasses}`}
        variant="outlined"
        {...btnProps}
      >
        <FontAwesomeIcon icon={faTwitter} />
      </ExternalButtonLink>

      <ExternalButtonLink
        href="http://www.facebook.com"
        className={`lg round ${borderClasses} ${variantClasses}`}
        variant="outlined"
        {...btnProps}
      >
        <FontAwesomeIcon icon={faInstagram} />
      </ExternalButtonLink>
    </Flex>
  )
}

FollowButtons.defaultProps = {
  borderClasses: 'hidden-border',
  direction: 'column',
}
FollowButtons.propTypes = {
  borderClasses: PropTypes.string,
  variant: PropTypes.oneOf(['light', 'dark']),
  direction: PropTypes.oneOf(['row', 'column']),
  btnProps: PropTypes.object,
}

export default memo(FollowButtons)
