import React, { memo } from 'react'
import styled from 'styled-components'
import Hidden from '@material-ui/core/Hidden'
import { Link } from 'gatsby'

import { useCollectionContext } from 'context/CollectionContext'

import Button from 'components/atoms/buttons/Button'
import Flex from 'components/atoms/Flex'
import SmallText from 'components/atoms/text/SmallText'
import VerticalDivider from 'components/atoms/dividers/VerticalDivider'

const Badge = styled(Flex)`
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.3);
  width: 2.6rem;
  height: 2.6rem;
  text-align: center;
`
const StyledLink = styled(Link)`
  display: flex;
  color: white;
`

const CollectionSummaryButton = () => {
  const { collection } = useCollectionContext()
  if (collection && collection.length > 0) {
    return (
      <>
        <Hidden mdUp implementation="css">
          <Button
            className="md round outline-light caps menu-btn"
            variant="outlined"
            aria-label="Number of items"
            ml={1}
          >
            {collection.length}
          </Button>
        </Hidden>
        <Hidden smDown implementation="css">
          <VerticalDivider
            bgColor="rgba(255,255,255,.4)"
            mx={3}
            style={{ float: 'left' }}
          />
          <StyledLink to="/collection">
            <Flex verticalCenter>
              <SmallText>Collection</SmallText>
              <Badge center ml={1}>
                <SmallText style={{ letterSpacing: 0 }}>
                  {collection.length}
                </SmallText>
              </Badge>
            </Flex>
          </StyledLink>
        </Hidden>
      </>
    )
  }
  return null
}

export default memo(CollectionSummaryButton)
