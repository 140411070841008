import React from 'react'
import { ThemeProvider } from 'styled-components'
import { MuiThemeProvider } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { InView } from 'react-intersection-observer'

import theme from 'constants/theme'
import themeMui from 'constants/theme-mui'
import GlobalStyle from 'styles/global.css.js'

import { useGlobalActionsContext } from 'context/GlobalContext'

import Head from './Head'
import Header from './Header'
import Footer from './Footer'
import LoadingOverlay from 'components/atoms/utils/LoadingOverlay'
import StatusMessage from 'components/atoms/utils/StatusMessage'
import IosInstall from 'components/atoms/utils/IosInstall'

const Content = styled.div`
  overflow: hidden;
  font-size: 1.6rem;
`

const Layout = ({ children, hero, metadata, headerProps }) => {
  const { updateSolidNav } = useGlobalActionsContext()

  return (
    <>
      <Head metadata={metadata} />
      <ThemeProvider theme={theme}>
        <MuiThemeProvider theme={themeMui}>
          {/* <div onScroll={(e) => updateSolidNav(e)}> */}
          <Header {...headerProps} />
          <Content>
            <InView
              className="in-view"
              as="div"
              onChange={(inView) => {
                updateSolidNav(!inView)
              }}
            />
            {hero}
            {children}
            <Footer />
          </Content>
          {/* </div> */}
          <LoadingOverlay />
          <StatusMessage />
          <IosInstall />
        </MuiThemeProvider>
      </ThemeProvider>

      <GlobalStyle />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  hero: PropTypes.node,
  metadata: PropTypes.object,
  headerProps: PropTypes.object,
}

export default Layout
