import React, { memo } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Img from 'gatsby-image'

import breakpoints from 'helpers/breakpoints'
import nl2br from 'helpers/nl2br'

import Section from 'components/atoms/layout/Section'
import SectionHeading from 'components/atoms/headings/SectionHeading'
import SectionWaveBg from 'components/atoms/layout/SectionWaveBg'
import SmallText from 'components/atoms/text/SmallText'
import Box from 'components/atoms/Box'
import ResponsivePlx from 'components/atoms/plx/ResponsivePlx'
import TextPlx from 'components/atoms/plx/TextPlx'

const ContainerGrid = styled(Grid)`
  align-items: center;
  .content {
    position: relative;
    z-index: 9;
  }
  .image {
    position: relative;
    z-index: 10;
  }
`

const StyledSectionWaveBg = styled(SectionWaveBg)`
  margin-left: -200px;
  padding-left: 200px;
  position: relative;
  z-index: 9;
`

const ContactDetails = styled(Box)`
  p {
    display: block;
    margin: 0;
  }
`

const Image = styled(Img)`
  > div {
    padding-bottom: 85% !important;
  }

  ${breakpoints.greaterThan('sm')`
    > div {
      padding-bottom: 65% !important;
    }
  `}

  ${breakpoints.greaterThan('md')`

    > div {
      padding-bottom: 60% !important;
    }
  `}

  ${breakpoints.greaterThan('lg')`

    > div {
      padding-bottom: 90% !important;
    }
  `}
  ${breakpoints.greaterThan('xl')`

    > div {
      padding-bottom: 66.7708% !important;
    }
  `}
`

const Details = ({ data }) => {
  const parallaxData = [
    {
      start: 'self',
      end: 'self',
      endOffset: '200vh',
      properties: [
        {
          startValue: 20,
          endValue: -20,
          property: 'translateY',
          unit: '%',
        },
      ],
    },
  ]

  return (
    <Section pt={{ xs: 0, xl: 10 }}>
      <ContainerGrid container spacing={0}>
        <Grid item xs={12} lg={6} xl={7} className="image">
          <ResponsivePlx breakpoint="lg" parallaxData={parallaxData}>
            <Image fluid={data.detailsSectionImage.fluid} />
          </ResponsivePlx>
        </Grid>
        <Grid item xs={12} lg={6} xl={5} className="content">
          <StyledSectionWaveBg px={{ lg: 4, xl: 6 }} py={{ xs: 10, xl: 15 }}>
            <TextPlx>
              <SectionHeading color="white" containerProps={{ mb: 4 }}>
                {data.detailsSectionHeading}
              </SectionHeading>
            </TextPlx>
            <TextPlx>
              <SmallText mb={1}>Location</SmallText>
            </TextPlx>
            <TextPlx>
              <ContactDetails
                dangerouslySetInnerHTML={{
                  __html: nl2br(data.addressNode.childMarkdownRemark.html),
                }}
              />
            </TextPlx>
            <TextPlx>
              <SmallText mt={3} mb={1}>
                Opening Times
              </SmallText>
            </TextPlx>
            <TextPlx>
              <ContactDetails
                dangerouslySetInnerHTML={{
                  __html: nl2br(data.openingTimesNode.childMarkdownRemark.html),
                }}
              />
            </TextPlx>

            <TextPlx>
              <SmallText mt={3} mb={1}>
                Contact Details
              </SmallText>
            </TextPlx>
            <TextPlx>{data.telephoneNumber}</TextPlx>
          </StyledSectionWaveBg>
        </Grid>
      </ContainerGrid>
    </Section>
  )
}

Details.propTypes = {
  data: PropTypes.object.isRequired,
}

export default memo(Details)
