import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import is from 'styled-is'

const Link = styled.a`
  color: inherit;

  ${is('noHoverColor')`
    &:hover {
      color: inherit;
    }
  `};

  ${is('fullWidth')`
    display: block;
    width: 100%;
  `};
`

const ExternalLink = ({ href, children, ...props }) => {
  return (
    <Link href={href} target="_blank" rel="noopener noreferrer" {...props}>
      {children}
    </Link>
  )
}

ExternalLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default memo(ExternalLink)
