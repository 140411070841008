import React, { memo, useEffect, useState } from 'react'
import { Snackbar } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import CheckIcon from '@material-ui/icons/Check'

import { useGlobalContext, useGlobalResetContext } from 'context/GlobalContext'

const StatusMessage = () => {
  const { statusMessage } = useGlobalContext()
  const { resetStatusMessage } = useGlobalResetContext()
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (statusMessage && statusMessage.message) {
      setOpen(true)
    }
  }, [statusMessage])

  return (
    <>
      {statusMessage && (
        <Snackbar
          open={open}
          onClose={handleClose}
          onExited={resetStatusMessage}
          transitionDuration={400}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          //   mouseEvent: false,
          // }}
        >
          <Alert
            iconMapping={{ success: <CheckIcon fontSize="inherit" /> }}
            variant="filled"
            severity={statusMessage.type}
          >
            {statusMessage.message}
          </Alert>
        </Snackbar>
      )}
    </>
  )
}

export default memo(StatusMessage)
