import React, { memo } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import { Formik, Form as FormikForm } from 'formik'
import { string, object } from 'yup'

import { useGlobalActionsContext } from 'context/GlobalContext'
import encodeForm from 'helpers/encodeForm'

import Section from 'components/atoms/layout/Section'
import Heading from 'components/atoms/headings/Heading'
import TextField from 'components/atoms/inputs/TextField'
import SubmitButton from 'components/atoms/forms/SubmitButton'
import FormGridItem from 'components/atoms/forms/FormGridItem'

const ContactForm = ({ heading }) => {
  const { setStatusMessage, setIsLoading } = useGlobalActionsContext()

  const handleSubmit = (values, actions) => {
    setIsLoading(true)
    fetch('/?no-cache=1', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: encodeForm({ 'form-name': 'contact', ...values }),
    })
      .then(() => {
        setIsLoading(false)
        setStatusMessage({
          type: 'success',
          message:
            'Your contact submission was successful. We will get back to you soon.',
        })
        actions.resetForm()
      })
      .catch(() => {
        setIsLoading(false)
        setStatusMessage({
          type: 'error',
          message:
            'Your contact submission was unsuccessful. Please try again.',
        })
      })
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <Section py={{ xs: 8, xl: 14 }}>
      <Container maxWidth="xl">
        <Heading
          $as="h2"
          headingSize="h2"
          mb={10}
          textAlign={{ xs: 'center', xl: 'left' }}
        >
          {heading}
        </Heading>

        <Formik
          initialValues={{
            firstName: '',
            surname: '',
            email: '',
            phone: '',
            country: '',
            subject: '',
            message: '',
          }}
          validationSchema={object().shape({
            firstName: string().required('Please enter your first name'),
            surname: string().required('Please enter your last name'),
            email: string()
              .email('Please supply a valid email address')
              .required('Email address is Required'),
            phone: string(),
            country: string(),
            subject: string().required('A subject is required'),
            message: string().required('A message is required'),
          })}
          onSubmit={(values, actions) => {
            handleSubmit(values, actions)
          }}
        >
          <FormikForm name="contact" data-netlify={true}>
            <Grid container spacing={10}>
              <FormGridItem xs={12} md={6}>
                <TextField
                  name="firstName"
                  label="First Name"
                  fullWidth
                  required
                />
              </FormGridItem>
              <FormGridItem xs={12} md={6}>
                <TextField name="surname" label="Surname" fullWidth required />
              </FormGridItem>

              <FormGridItem xs={12} md={6}>
                <TextField
                  name="email"
                  label="Email"
                  fullWidth
                  required
                  type="email"
                />
              </FormGridItem>
              <FormGridItem xs={12} md={6}>
                <TextField name="phone" label="Phone" fullWidth />
              </FormGridItem>
              <FormGridItem xs={12} md={6}>
                <TextField
                  name="country"
                  label="Country"
                  fullWidth
                  type="country"
                />
              </FormGridItem>
              <FormGridItem xs={12} md={6}>
                <TextField name="subject" label="Subject" fullWidth required />
              </FormGridItem>

              <FormGridItem xs={12}>
                <TextField
                  name="message"
                  label="Message"
                  multiline
                  rowsMax={5}
                  fullWidth
                  required
                />
              </FormGridItem>
            </Grid>
            <SubmitButton buttonProps={{ mt: 10 }} />
          </FormikForm>
        </Formik>
      </Container>
    </Section>
  )
}

ContactForm.propTypes = {
  heading: PropTypes.string.isRequired,
}

export default memo(ContactForm)
