import { normalize } from 'styled-normalize'
import { createGlobalStyle } from 'styled-components'
import theme from 'constants/theme'

const GlobalStyle = createGlobalStyle`
  ${normalize}

  @font-face {
    font-display: swap;
  }

  html,
  body {
    margin: 0;
    font-size: 62.5%;
    font-family: ${theme.fonts.sans};
    font-weight: 400;
    line-height: 1.8;
    color: ${theme.colors.textDefault}
    background: white;
  }

  .fonts-loaded {
    .serif {
      font-family: ${theme.fonts.serif};
    }
  }

  ul,
  li {
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
    transition: color 250ms;


    &:hover {
      color: ${theme.colors.primary};
    }
  }

  figure {
    margin: 0;
  }

  .caps {
    text-transform: uppercase !important;
  }

  .foreground {
    position: relative;
    z-index: 110;
  }
`

export default GlobalStyle
