import React, { memo } from 'react'

import theme from 'constants/theme'
import HorizontalDivider from 'components/atoms/dividers/HorizontalDivider'

const HeadingDivider = ({ ...props }) => (
  <HorizontalDivider bg={theme.colors.primary} {...props} />
)

HeadingDivider.defaultProps = {
  width: '6.6rem',
  mt: 2,
  mb: 2,
}

export default memo(HeadingDivider)
