import React, { memo } from 'react'
import styled from 'styled-components'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

import { useGlobalContext } from 'context/GlobalContext'

const StyledBackdrop = styled(Backdrop)`
  z-index: 1000000;
  justify-content: center;
  align-items: center;
  color: white;
`

const LoadingOverlay = (props) => {
  const { isLoading } = useGlobalContext()

  return (
    <StyledBackdrop open={isLoading} {...props}>
      <CircularProgress color="inherit" />
    </StyledBackdrop>
  )
}

export default memo(LoadingOverlay)
