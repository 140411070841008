import React, { memo } from 'react'
import PropTypes from 'prop-types'

import Button from 'components/atoms/buttons/Button'
import ExternalLink from 'components/atoms/links/ExternalLink'

const ExternalButtonLink = ({
  href,
  children,
  noHoverColor,
  fullWidth,
  linkProps,
  ...props
}) => {
  return (
    <ExternalLink href={href} noHoverColor={noHoverColor} {...linkProps}>
      <Button {...props} fullWidth={fullWidth}>
        {children}
      </Button>
    </ExternalLink>
  )
}
ExternalButtonLink.propTypes = {
  href: PropTypes.string.isRequired,
  noHoverColor: PropTypes.bool,
  fullWidth: PropTypes.bool,
  LinkProps: PropTypes.object,
  children: PropTypes.node.isRequired,
}
export default memo(ExternalButtonLink)
