import React, { memo } from 'react'
import styled from 'styled-components'

const StyledJoin = styled.div`
  height: 80px;
  width: 1px;
  background-color: ${props => props.theme.colors.primary};
  margin: 0 auto;
  position: absolute;
  top: ${props => (props.top ? '-40px' : 'auto')};
  bottom: ${props => (props.top ? 'auto' : '-40px')};
  left: 50%;
  z-index: 100;
`

const SectionJoin = props => <StyledJoin {...props} />

export default memo(SectionJoin)
