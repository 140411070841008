import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Div100vh from 'react-div-100vh'
import Drawer from '@material-ui/core/Drawer'
import { Link } from 'gatsby'

import breakpoints from 'helpers/breakpoints'
import mainNav from 'constants/nav'

import Flex from 'components/atoms/Flex'
import Box from 'components/atoms/Box'

const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    right: 0;
    ${breakpoints.greaterThan('xl')`
      right: 50%;
  `}
  }
`

const StyledSidebarNav = styled(Div100vh)`
  font-size: 1.6rem;
  text-align: center;
  width: 100%;

  ul {
    list-style: none;

    li {
      line-height: 1;
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`

const StyledLink = styled(Link)`
  font-family: ${(props) => props.theme.fonts.serif};
  // font-size: ${(props) => props.theme.fontSizes[6]};
  color: ${(props) => props.theme.colors.textDefault};
  font-weight: ${(props) => props.theme.fontWeights.medium};
  display: block;
  position: relative;
  z-index: 999999;
`

const SidebarNav = ({ open, hideSidebar }) => {
  return (
    <StyledDrawer
      open={open}
      onClose={hideSidebar}
      className="navDrawer"
      ModalProps={{
        keepMounted: true,
        disablePortal: true,
      }}
    >
      <StyledSidebarNav>
        <Flex full>
          <Box p={{ xs: 2, md: 4, lg: 8 }} flex={1}>
            <Flex full flexDirection="column">
              <Box my={'auto'}>
                <Flex full flexDirection="column">
                  <Box fontSize={{ xs: 5, md: 6 }}>
                    <ul>
                      {mainNav.map((item) => {
                        return (
                          <li key={`nav-${item.slug}`}>
                            <StyledLink
                              to={`/${item.slug === 'home' ? '' : item.slug}`}
                              onClick={() => hideSidebar()}
                            >
                              {item.label}
                            </StyledLink>
                          </li>
                        )
                      })}
                    </ul>
                  </Box>
                </Flex>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </StyledSidebarNav>
    </StyledDrawer>
  )
}

SidebarNav.propTypes = {
  open: PropTypes.bool.isRequired,
  hideSidebar: PropTypes.func.isRequired,
}
export default SidebarNav
