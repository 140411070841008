import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { color, space } from 'styled-system'
const StyledSection = styled.section`
  ${color}
  ${space}
  position: relative;
  overflow-x: ${props => (props.overflowXHidden ? 'hidden' : 'initial')};
  overflow-y: ${props => (props.overflowYHidden ? 'hidden' : 'initial')};
`

const Section = ({ children, ...props }) => {
  return <StyledSection {...props}>{children}</StyledSection>
}

Section.defaultProps = {
  bg: 'white',
  color: 'inherit',
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
  overflowXHidden: PropTypes.bool,
  overflowYHidden: PropTypes.bool,
}

export default memo(Section)
