import React, { memo } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${props => props.theme.colors.darkBg};

  .gatsby-image-wrapper {
    opacity: 0.5;
    height: 100%;
  }
`

const BackgroundImageContainer = props => {
  return <Container {...props} />
}

export default memo(BackgroundImageContainer)
