import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

import Layout from 'components/organisms/global/Layout'
import ImageHero from 'components/molecules/hero/ImageHero'
import Heading from 'components/atoms/headings/Heading'
import IntroText from 'components/organisms/sections/IntroText'
import Details from 'components/organisms/_page-specific/contact/Details'
import ContactForm from 'components/organisms/_page-specific/contact/ContactForm'

const Contact = ({ data }) => {
  const page = data.datoCmsContact

  const metaObj = {
    title: page.seoMetadata.title,
    description: page.seoMetadata.description,
    image: {
      src: page.metaImage.fixed.src,
      width: page.metaImage.fixed.width,
      height: page.metaImage.fixed.height,
    },
  }

  return (
    <Layout
      metadata={metaObj}
      headerProps={{ navImage: page.mainNavigationImage }}
    >
      <ImageHero images={page.heroSectionBackgroundImage}>
        <Heading as="h1" headingSize="jumbo" color="white">
          {page.heroSectionHeading}
        </Heading>
      </ImageHero>
      <IntroText text={page.introText} />
      <Details data={page} />
      <ContactForm heading={page.emailFormHeading} />
    </Layout>
  )
}

Contact.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Contact

export const pageQuery = graphql`
  {
    datoCmsContact {
      seoMetadata {
        description
        title
      }
      metaImage: heroSectionBackgroundImage {
        fixed(
          width: 1024
          height: 512
          imgixParams: {
            fit: "crop"
            w: "1024"
            h: "512"
            fm: "jpg"
            auto: "compress"
          }
        ) {
          width
          height
          src
        }
      }
      addressNode {
        childMarkdownRemark {
          html
        }
      }
      detailsSectionHeading
      emailFormHeading
      detailsSectionImage {
        fluid(maxWidth: 1000, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      heroSectionBackgroundImage {
        fluid(maxWidth: 1400, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
      heroSectionHeading
      introText
      mainNavigationImage {
        fluid(
          maxWidth: 600
          imgixParams: {
            fit: "crop"
            ar: "3:4"
            w: "600"
            fm: "jpg"
            auto: "compress"
          }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
      openingTimesNode {
        childMarkdownRemark {
          html
        }
      }
      telephoneNumber
    }
  }
`
