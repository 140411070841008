import React, { memo, useState, useEffect } from 'react'
import styled from 'styled-components'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import { useGlobalContext } from 'context/GlobalContext'

import AppleShare from 'images/apple-share.svg'

const ShareLogo = styled(AppleShare)`
  height: 2.5rem;
  width: auto;
  vertical-align: middle;

  path {
    fill: white;
  }
`
const StyledSnackbar = styled(Snackbar)`  
  .MuiSnackbarContent-root {
    flex-wrap: initial;
  }
`

// Detects if device is on iOS
const isIos = () => {
  if (typeof window !== 'undefined') {
    const userAgent = window.navigator.userAgent.toLowerCase()
    return /iphone|ipad|ipod/.test(userAgent)
  }
  return null
}
// Detects if device is in standalone mode
const isInStandaloneMode = () => {
  if (typeof window !== 'undefined') {
    return 'standalone' in window.navigator && window.navigator.standalone
  }
  return null
}

const IosInstall = () => {
  const { solidNav } = useGlobalContext()
  const [open, setOpen] = useState(false)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  useEffect(() => {
    if (isIos() && !isInStandaloneMode()) {
      if (solidNav !== true) {
        setOpen(true)
      } else {
        setOpen(false)
      }
    }
  }, [solidNav])

  const message = (
    <div>
      Install this webapp on your device. Tap <ShareLogo /> then Add to
      homescreen.
    </div>
  )

  return (
    <StyledSnackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={open}
      message={message}
      action={
        <>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
      }
    />
  )
}

export default memo(IosInstall)
