import theme from 'constants/theme'
import breakpoints from 'constants/breakpoints'
import { createMuiTheme } from '@material-ui/core/styles'

const muiThemeStyles = createMuiTheme({
  breakpoints: {
    values: {
      xs: breakpoints.xs,
      sm: breakpoints.sm,
      md: breakpoints.md,
      lg: breakpoints.lg,
      xl: breakpoints.xl,
    },
  },
  spacing: 10,
  typography: {
    fontFamily: [theme.fonts.sans].join(','),
    htmlFontSize: 10,
    fontSize: 14,
  },
  shape: { borderRadius: theme.borderRadius.small },
  palette: {
    primary: {
      main: theme.colors.primary,
      contrastText: '#fff',
    },
  },
  overrides: {
    MuiSnackbar: {
      root: {
        padding: 0,
      },
    },
    MuiSnackbarContent: {
      root: {
        fontSize: '1.6rem',
        color: 'white',
      },
      message: {
        fontSize: '1.6rem',
        color: 'white',
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: '1.6rem',
      },
    },
    MuiButton: {
      root: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
        '&:focus': {
          boxShadow: 'none',
        },
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
        '&:focus': {
          boxShadow: 'none',
        },
      },
    },
    // MuiBackdrop: {
    //   root: {
    //     backgroundColor: 'rgba(47,52,80,.5)',
    //   },
    // },
    MuiMenuItem: {
      root: {
        fontSize: '1.2rem',
        textTransform: 'uppercase',
        letterSpacing: '.1rem',
      },
    },

    MuiSelect: {
      select: {
        minHeight: '42px',
      },
      selectMenu: {
        minHeight: '42px',
        lineHeight: '42px',
        paddingLeft: '15px',
        fontSize: '1.2rem',
        fontWeight: 400,
        textTransform: 'uppercase',
        letterSpacing: '.1rem',
        color: theme.colors.secondary,
      },
      icon: {
        right: '7px',
        color: theme.colors.secondary,
      },
    },
    MuiTabs: {
      root: {
        position: 'relative',
        overflow: 'visible',
        borderBottom: '1px solid #e8e8e8',
        '&:after': {
          content: '""',
          width: '2000px',
          height: '1px',
          backgroundColor: '#e8e8e8',
          position: 'absolute',
          bottom: '-1px',
          left: '100%',
        },
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        fontWeight: 400,
        transition: `color ${theme.transitions.default}`,
        minWidth: 'auto !important',
        '&:hover': {
          color: theme.colors.primary,
          opacity: 1,
        },
        '&$selected': {
          color: theme.colors.textDefault,
        },
        '&:focus': {
          color: theme.colors.textDefault,
        },
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: 16,
        fontWeight: theme.fontWeights.normal,
        color: theme.colors.textDefault,
        marginBottom: theme.space[1] + theme.spaceUnit,
      },
      asterisk: {
        color: theme.colors.primary,
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottomColor: 'rgba(26,26,26,0.2)',
        },
      },
    },
    MuiInputBase: {
      input: {
        padding: '20px 0',
      },
      multiline: {
        padding: '20px 0',
      },
    },
    MuiInputLabel: {
      formControl: {
        transform: 'translate(0, 37px) scale(1)',
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: 'black',
        fontSize: '1.2rem',
        textTransform: 'uppercase',
        padding: '10px',
        borderRadius: '0',
        letterSpacing: '1px',
      },
      arrow: {
        color: 'black',
      },
    },
  },
})

export default muiThemeStyles
