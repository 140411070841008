import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import theme from 'constants/theme'

import Box from 'components/atoms/Box'

const StyledDivider = styled(Box)`
  width: ${props => props.width};
  height: ${props => props.height};
  background: ${props => props.bg};
`

const HorizontalDivider = ({ ...props }) => {
  return <StyledDivider {...props} />
}

HorizontalDivider.defaultProps = {
  bg: theme.colors.outlineDark,
  width: '100%',
  height: '1px',
}

HorizontalDivider.propTypes = {
  bg: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
}

export default memo(HorizontalDivider)
