import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import theme from 'constants/theme'

import Box from 'components/atoms/Box'

const Divider = styled(Box)`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background: ${(props) => props.bgColor};
`

const VerticalDivider = (props) => <Divider {...props} />
VerticalDivider.defaultProps = {
  bgColor: theme.colors.primary,
  width: '1px',
  height: '100%',
}

VerticalDivider.propTypes = {
  bgColor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
}

export default memo(VerticalDivider)
