import React, { memo, useState, forwardRef } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Link, StaticQuery } from 'gatsby'
import MuiContainer from '@material-ui/core/Container'
import CloseIcon from '@material-ui/icons/Close'
import Hidden from '@material-ui/core/Hidden'

import {
  useSlidesOverlayContext,
  useSlidesOverlayActionsContext,
} from 'context/SlidesOverlayContext'
import { useGlobalContext } from 'context/GlobalContext'
import breakpoints from 'helpers/breakpoints'

import Logo from 'images/logo.svg'
import MobilePhone from 'images/mobile.svg'
import Box from 'components/atoms/Box'
import Flex from 'components/atoms/Flex'
import Button from 'components/atoms/buttons/Button'
import CollectionSummaryButton from 'components/atoms/buttons/CollectionSummaryButton'
import Burger from 'components/atoms/buttons/Burger'
import SidebarNav from './SidebarNav'
import SidebarContent from './SidebarContent'
import SmallText from 'components/atoms/text/SmallText'

const collapsed = css`
  background: black;
  padding-top: ${(props) => props.theme.space[1] + props.theme.spaceUnit};
  padding-bottom: ${(props) => props.theme.space[1] + props.theme.spaceUnit};

  .logo {
    width: 120px;
    display: block;
  }
`

const Container = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  color: white;
  z-index: 1301;
  background-color: rgba(${(props) => props.theme.colors.secondaryRgb}, 0);

  transition: background-color ${(props) => props.theme.transitions.default},
    padding-top ${(props) => props.theme.transitions.default},
    padding-bottom ${(props) => props.theme.transitions.default};

  .menu-btn {
    transition: border-color ${(props) => props.theme.transitions.default};

    .line {
      transition: background-color ${(props) =>
        props.theme.transitions.default};
    }

    .outer-label {
      transition: color ${(props) => props.theme.transitions.default};
    }
  }

  .signInBtn {
    transition: height ${(props) => props.theme.transitions.default},
      padding-left ${(props) => props.theme.transitions.default},
      padding-right ${(props) => props.theme.transitions.default};
  }

  .logo {
    transition: width ${(props) => props.theme.transitions.default};
    path {
      // transition: fill ${(props) => props.theme.transitions.default};
    }
  }
  .mobile-tel {
    color: white;
  }

  ${breakpoints.lessThan('md')`
    ${collapsed}
  `}

  ${breakpoints.lessThan('sm')`

      .outer-label {
        span.outer-label {
          display: none;
        }
      }

  `}





  &.solid {
    ${collapsed}
  }

  &.dark {
    ${breakpoints.greaterThan('md')`
      background-color: rgba(255, 255, 255, 0) !important;
      .menu-btn {
        border-color: ${(props) => props.theme.colors.textDefault};
        .line {
          background-color: ${(props) => props.theme.colors.textDefault};
        }
        .outer-label {
          color: ${(props) => props.theme.colors.textDefault};
        }
      }
      .logo,
      .close {
        path {
          fill: ${(props) => props.theme.colors.textDefault} !important;
        }
      }
      .right {
        transition: color ${(props) => props.theme.transitions.default};
        color: ${(props) => props.theme.colors.textDefault};
      }
      .mobile-icon {
        path {
          transition: fill ${(props) => props.theme.transitions.default};
          fill: ${(props) => props.theme.colors.textDefault};
          &.button {
            transition: stroke ${(props) => props.theme.transitions.default};
            stroke: ${(props) => props.theme.colors.textDefault};
          }
        }
      }
      .mobile-tel {
        color: ${(props) => props.theme.colors.textDefault};
      }
      }



      a {
        color: ${(props) => props.theme.colors.textDefault};
        transition: color 0.2s ease;
        text-decoration: none;

        &:hover {
          color: inherit;
        }
      }
    `}  

    
      > * {
        flex: 1;
      }
  }
`

const StyledMuiContainer = styled(MuiContainer)`
  font-size: 1.6rem;
  transition: padding-left ${(props) => props.theme.transitions.default},
    padding-right ${(props) => props.theme.transitions.default};
  &.reducedPadding {
    padding-left: ${(props) => props.theme.space[2] + props.theme.spaceUnit};
    padding-right: ${(props) => props.theme.space[2] + props.theme.spaceUnit};
  }
`

const MainLogo = styled(Logo)`
  width: 138px;
`
const MobileIcon = styled(MobilePhone)`
  width: 16px;
  margin-right: 14px;
  path {
    fill: white;
    &.button {
      stroke: white;
    }
  }

  &.button-icon {
    width: 14px;
    margin: 0;
  }
`
// eslint-disable-next-line react/display-name
const Header = (props) => {
  const { open } = useSlidesOverlayContext()
  const { solidNav } = useGlobalContext()
  const { closeSlidesOverlay } = useSlidesOverlayActionsContext()
  const [sidebar, setSidebar] = useState(false)

  let containerClass = open ? 'dark' : ''

  if (props.containerClass && !solidNav) {
    containerClass = props.containerClass
  }

  if (solidNav) {
    containerClass = containerClass + ' solid reducedPadding'
  }

  if (sidebar) {
    containerClass = containerClass + ' dark'
  }
  const hideSidebar = () => {
    setSidebar(false)
  }

  return (
    <StaticQuery
      query={graphql`
        {
          datoCmsContact {
            telephoneNumber
          }
        }
      `}
      render={(data) => (
        <>
          <Container py={{ xs: 1, sm: 2 }} className={containerClass}>
            <StyledMuiContainer maxWidth={false}>
              <Flex center fullWidth>
                <Box flex={1}>
                  <Button
                    className="md round outer-label outline-light caps menu-btn"
                    outerLabel={open ? 'close' : 'Menu'}
                    onClick={() =>
                      open ? closeSlidesOverlay() : setSidebar(!sidebar)
                    }
                    variant="outlined"
                    aria-label={sidebar || open ? 'Close' : 'Menu'}
                  >
                    {sidebar || open ? (
                      <CloseIcon className="close" />
                    ) : (
                      <Burger />
                    )}
                  </Button>
                </Box>

                {open ? (
                  <MainLogo className="logo" />
                ) : (
                  <Link to="/" aria-label="Home">
                    <MainLogo className="logo" />
                  </Link>
                )}

                <Flex flex={1} className="right" justifyContent="flex-end">
                  <Flex verticalAlign>
                    <a
                      href={`tel:${data.datoCmsContact.telephoneNumber.replace(
                        /\s+/g,
                        ''
                      )}`}
                      aria-label={`Call us on: ${data.datoCmsContact.telephoneNumber.replace(
                        /\s+/g,
                        ''
                      )}`}
                    >
                      <Hidden mdUp implementation="css">
                        <Button
                          className="md round outline-light caps menu-btn"
                          variant="outlined"
                          aria-label="Telephone Button"
                        >
                          <MobileIcon className="button-icon mobile-icon" />
                        </Button>
                      </Hidden>
                      <Hidden smDown implementation="css">
                        <Flex verticalCenter>
                          <MobileIcon className="mobile-icon" />
                          <SmallText className="mobile-tel">
                            {data.datoCmsContact.telephoneNumber}
                          </SmallText>
                        </Flex>
                      </Hidden>
                    </a>
                    <CollectionSummaryButton />
                  </Flex>
                </Flex>
              </Flex>
            </StyledMuiContainer>
          </Container>
          <SidebarNav open={sidebar} hideSidebar={hideSidebar} />
          <SidebarContent
            open={sidebar}
            hideSidebar={hideSidebar}
            navImage={props.navImage}
          />
        </>
      )}
    />
  )
}

Header.propTypes = {}
export default memo(Header)
