import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Img from 'gatsby-image'
import Container from '@material-ui/core/Container'

import theme from 'constants/theme'

import Logo from 'images/logo.svg'
import Section from 'components/atoms/layout/Section'
import Divider from 'components/atoms/dividers/HorizontalDivider'
import Flex from 'components/atoms/Flex'
import Heading from 'components/atoms/headings/Heading'
import TextPlx from 'components/atoms/plx/TextPlx'

const LogoIcon = styled(Logo)`
  width: 138px;

  .text {
    display: none;
  }

  .icon {
    path {
      fill: ${props => props.theme.colors.primary} !important;
    }
  }
`

const IntroText = ({ text, ...props }) => {
  return (
    <Section py={{ xs: 6, lg: 8, xl: 10 }} {...props}>
      <Container maxWidth="lg">
        <Flex flexDirection="column" center textAlign="center">
          <TextPlx>
            <LogoIcon />
          </TextPlx>
          <TextPlx>
            <Heading as="p" headingSize="h4" lineHeight="1.5">
              {text}
            </Heading>
          </TextPlx>
          <TextPlx>
            <Divider
              width="20px"
              bg={theme.colors.primary}
              mt={{ xs: 3 }}
              mb={{ xs: 0 }}
            />
          </TextPlx>
        </Flex>
      </Container>
    </Section>
  )
}

IntroText.propTypes = {
  text: PropTypes.string.isRequired,
}

export default memo(IntroText)
