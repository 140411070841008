import React from 'react'
import styled, { keyframes } from 'styled-components'
import { Link, StaticQuery, graphql } from 'gatsby'
import Container from '@material-ui/core/Container'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import Img from 'gatsby-image'

import breakpoints from 'helpers/breakpoints'
import mainNav from 'constants/nav'
import theme from 'constants/theme'

import Flex from 'components/atoms/Flex'
import Box from 'components/atoms/Box'
import Button from 'components/atoms/buttons/Button'
import BackgroundImageContainer from 'components/atoms/media/BackgroundImageContainer'
import Logo from 'images/logo-footer.svg'
import Divider from 'components/atoms/dividers/HorizontalDivider'
import FollowButtons from 'components/molecules/social/FollowButtons'
import SmallHeading from 'components/atoms/headings/SmallHeading'
import SmallText from 'components/atoms/text/SmallText'
import ExternalLink from 'components/atoms/links/ExternalLink'
import SectionJoin from 'components/atoms/dividers/SectionJoin'

const StyledFooter = styled(Box)`
  background: black;
  font-size: 1.6rem;
  color: white;
  text-align: center;
  color: #acbcc8;
`

const Nav = styled.ul`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  list-style: none;
  padding-top: 25px;
  padding-bottom: 45px;

  ${breakpoints.greaterThan('xl')`
    flex-direction: row;
  `}
`

const StyledLink = styled(Link)`
  color: white;
  display: block;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  font-size: 1.2rem;
  font-weight: ${(props) => props.theme.fontWeights.semiBold};
  padding: 15px 25px;

  ${breakpoints.greaterThan('xl')`
    display: inline-block;
    padding: 20px 25px;
  `}
`

const StyledBackgroundImageContainer = styled(BackgroundImageContainer)`
  background: none;
  opacity: 0.19;
  .gatsby-image-wrapper {
    opacity: 1;
  }
`

const MainLogo = styled(Logo)`
  width: 120px;
`

const FollowHeading = styled(SmallHeading)`
  letter-spacing: 0.4rem;
`

const TopButton = styled(Button)`
  min-width: 40px !important;
  width: 40px !important;
  height: 40px !important;
  padding: 0 !important;
`

const FooterBottomText = styled(SmallText)`
  font-size: 1rem;
  color: #787a7c;
`

// Create the keyframes
const pulse = keyframes`
  0%
  {
    transform: scale( 1 );
  }
  30%
  {
    transform: scale( 1 );
  }
  50%
  {
    transform: scale( 1.2 );
  }
  70%
  {
    transform: scale( 1 );
  }
  100%
  {
    transform: scale( 1 );
  }
`
const LoveIcon = styled(FavoriteBorderIcon)`
  height: 1.3rem;
  animation: ${pulse} 1s linear infinite;
  vertical-align: middle;
`

const Footer = () => {
  const scrollToTop = () => {
    if (typeof window !== 'undefined') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }

  const footerDisplay = { xs: 'none', sm: 'block' }

  return (
    <StaticQuery
      query={graphql`
        query HeadingQuery {
          datoCmsGlobalElement {
            footerBackgroundImage {
              fluid(
                maxWidth: 1200
                imgixParams: {
                  fm: "jpg"
                  auto: "compress"
                  sat: -100
                  w: "1200"
                }
              ) {
                ...GatsbyDatoCmsFluid_noBase64
              }
            }
          }
        }
      `}
      render={(data) => (
        <footer>
          <StyledFooter className="foreground" pt={{ xs: 10 }} pb={{ xs: 6 }}>
            <SectionJoin top />
            <StyledBackgroundImageContainer>
              <Img
                fluid={data.datoCmsGlobalElement.footerBackgroundImage.fluid}
              />
            </StyledBackgroundImageContainer>

            <Container maxWidth="xl" className="foreground">
              <MainLogo />
              <Nav>
                {mainNav.map((item) => {
                  if (item.slug !== 'home')
                    return (
                      <li key={`nav-${item.slug}`}>
                        <StyledLink to={`/${item.slug}`}>
                          {item.label}
                        </StyledLink>
                      </li>
                    )
                })}
              </Nav>
              {/* <Divider
                mt={4}
                mb={6}
                bg={theme.colors.outlineExtraLight}
                display={footerDisplay}
              />

              <FollowHeading noDivider headingSize="h5" color="white" mb={2}>
                Follow Us
              </FollowHeading>

              <FollowButtons
                variant="light"
                direction="row"
                borderClasses="outline-extra-light"
                btnProps={{ mx: '6px' }}
              /> */}
            </Container>
            <Container maxWidth="lg" className="foreground">
              <Flex fullWidth center mt={7} mb={4}>
                <Box flex={1} display={footerDisplay}>
                  <Divider bg={theme.colors.outlineExtraLight} />
                </Box>
                <TopButton
                  variant="outlined"
                  className="outline-extra-light sharp lg"
                  onClick={scrollToTop}
                  aria-label="Scroll to the top of the page"
                >
                  <ExpandLessIcon />
                </TopButton>
                <Box flex={1} display={footerDisplay}>
                  <Divider bg={theme.colors.outlineExtraLight} />
                </Box>
              </Flex>

              <FooterBottomText>
                Copyright {new Date().getFullYear()}. All rights reserved.
                <br />
                Created with <LoveIcon /> at{' '}
                <ExternalLink href="http://www.lowrell.com">
                  Lowrell
                </ExternalLink>
                .
              </FooterBottomText>
            </Container>
          </StyledFooter>
        </footer>
      )}
    />
  )
}

export default Footer
