import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import is from 'styled-is'

import theme from 'constants/theme'

import HeadingBase from 'components/atoms/headings/Heading'
import Divider from 'components/atoms/dividers/HeadingDivider'

const Component = ({ $as, ...rest }) => {
  return <HeadingBase as={$as} {...rest} />
}

const Heading = styled(Component)`
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  font-family: ${props => props.theme.fonts.serif};
  color: ${props => (props.color ? props.color : 'inherit')};

  ${is('leftBorder')`
    border-left: 4px solid ${props => props.theme.colors.primary};
    padding-left: 20px;
  `};
`

const Small = ({ children, noDivider, ...props }) => {
  return (
    <>
      <Heading
        $as="h4"
        mb={1}
        fontWeight="semiBold"
        headingSize="1.45rem"
        {...props}
      >
        {children}
      </Heading>
      {!noDivider && <Divider width="20px" mt="0px" />}
    </>
  )
}

Small.propTypes = {
  children: PropTypes.node.isRequired,
  noDivider: PropTypes.bool,
}

export default memo(Small)
