import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Heading as Base } from 'rebass'

const Heading = props => {
  let fontSize = ''
  switch (props.headingSize) {
    case 'h1':
      fontSize = { xs: 4, sm: 5, xl: 6 }
      break
    case 'h2':
      fontSize = { xs: 4, xl: 5 }
      break
    case 'h3':
      fontSize = { xs: 3, xl: '3.5rem' }
      break
    case 'h4':
      fontSize = { xs: '2.5rem', sm: '2.7rem', md: '2.9rem', lg: '3.2rem' }
      break
    case 'h5':
      fontSize = { xs: '2.2rem', sm: '2.3rem', md: '2.4rem', lg: '2.5rem' }
      break
    case 'h6':
      fontSize = { xs: '1.8rem', sm: '2rem', md: '2.1rem', lg: '2.2rem' }
      break
    case 'small':
      fontSize = '1.26rem'
      break
    case 'jumbo':
      fontSize = { xs: 5, lg: 6, xl: 9 }
      break
    default:
      fontSize = props.headingSize
  }
  return <Base fontSize={fontSize} {...props} />
}

Heading.defaultProps = {
  fontFamily: 'serif',
  fontWeight: 'regular',
  lineHeight: 1.2,
  color: 'textDefault',
}

Heading.propTypes = {
  headingSize: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
}

export default memo(Heading)
