import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { smallText } from 'styles/small-text.css'

import Box from 'components/atoms/Box'

const Small = styled(Box)`
  ${smallText}
`

const SmallText = ({ children, ...props }) => (
  <Small {...props}>{children}</Small>
)

SmallText.propTypes = {
  children: PropTypes.node.isRequired,
}

export default memo(SmallText)
