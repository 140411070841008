/* eslint-disable prettier/prettier */
import React, { memo } from 'react'
import PropTypes from 'prop-types'
import MuiButton from '@material-ui/core/Button'
import { space, flexbox } from 'styled-system'
import styled from 'styled-components'
import { darken } from 'polished'

const StyledButton = styled(MuiButton)`
  ${space}
  ${flexbox}
  font-size: ${(props) => (props.iconSize ? 2 : 1.2)}rem;
  letter-spacing: 0.2rem;
  font-weight: 400;
  transition: background-color ${(props) => props.theme.transitions.default},
    color ${(props) => props.theme.transitions.default},
    border-color ${(props) => props.theme.transitions.default};

  .MuiButton-label {
    transition: color ${(props) => props.theme.transitions.default};
  }

  &.outer-label {
    position: relative;
  }

  &.outline-light {
    color: white;
    border-color: ${(props) => props.theme.colors.outlineLight};
  
  }


  &.primary-bg {
      color: white;
      background: ${(props) => props.theme.colors.primary};  
    &:hover {
      background-color: ${(props) => props.theme.colors.darkBg}; 
    }       
  }

  &.dark-bg {
      color: white;
      background: ${(props) => props.theme.colors.darkBg};  
    &:hover {
      background-color: ${(props) => props.theme.colors.primary}; 
    }       
  }

  &.outline-extra-light {
    color: white;
    border-color: ${(props) => props.theme.colors.outlineExtraLight};
    &:hover {
      background-color: rgba(255,255,255,.14);
    }    
  }

  &.outline-dark {
    color: inherit;
    border-color: ${(props) => props.theme.colors.outlineDark};
  }

  &.trans-dark {
    background-color: rgba(0,0,0,.13);
    border: none;
    color: white;

    &:hover {
      background-color: rgba(0,0,0,.18);
    }
  }

  &.sm {
    height: 28px;
    border-radius: 14px;
    padding: 0 1.3rem;
    text-transform: initial;
    letter-spacing: initial;
  }

  &.md {
    height: 40px;
    border-radius: 20px;
    padding: 0 1.3rem;
    text-transform: initial;
    letter-spacing: initial;

    &.round {
      width: 40px;
      min-width: 40px;
      padding: 0;
    }
  }

  &.lg {
    height: 50px;
    border-radius: 25px;

    &.round {
      width: 50px;
      min-width: 50px;
      padding: 0;
    }

    svg {
      font-size: 1.6rem;
    }
  }

  &.hover-white-bg {
    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }
  }

  &.lg-plus {
    border-radius: 28px;
    height: 56px;
    padding: 0 4rem;

    svg {
      line-height: 56px;
    }

    &.round {
      width: 56px;
      min-width: 56px;
      padding: 0;
    }
  }

  &.xl {
    border-radius: 32px;
    height: 64px;
    padding: 0 4rem;
  }

  &.hidden-border {
    border-color: rgba(255, 255, 255, 0);
  }

  &.double-border {
    position: relative;
    &:before {
      content: ' ';
      position: absolute;
      top: 1px;
      left: 1px;
      right: 1px;
      bottom: 1px;
      border-width: 1px;
      border-style: solid;
      border-color: ${(props) =>
        props.innerBorderColor ? props.innerBorderColor : 'white'};
    }
  }  

  &.sharp {
    border-radius: 0;
  }

  &.MuiButton-containedPrimary {
    &,
    &:hover {
      .MuiButton-label {
        color: #fff;
      }
    }
  }

  &.content-btn {
    background: ${(props) => props.theme.colors.greyLight};

    &:hover {
      background: ${(props) => darken(0.05, props.theme.colors.greyLight)};

      .outer-label {
        color: ${(props) => props.theme.colors.primary};
      }
    }

    .outer-label {
      left: 50%;
      text-transform: uppercase;
      letter-spacing: 0.3rem;
      font-weight: ${(props) => props.theme.fontWeights.bold};

      &:before {
        content: '';
        display: inline-block;
        width: 10px;
        height: 1px;
        background: ${(props) => props.theme.colors.primary};
        vertical-align: middle;
        margin-right: 10px;
      }
    } 
  } 

`

const OuterLabel = styled.span`
  display: block;
  position: absolute;
  left: 130%;
  top: 50%;
  white-space: nowrap;
  transform: translateY(-50%);
  transition: color ${(props) => props.theme.transitions.default};
`

const Button = ({ children, className, outerLabel, ...props }) => {
  let outerLabelContainer
  if (outerLabel) {
    outerLabelContainer = (
      <OuterLabel className="outer-label">{outerLabel}</OuterLabel>
    )
  }

  return (
    <StyledButton className={className} {...props}>
      {children}
      {outerLabelContainer}
    </StyledButton>
  )
}

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
  ]),
  outerLabel: PropTypes.string,
  className: PropTypes.string,
}

export default memo(Button)
