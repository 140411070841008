import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { Location } from '@reach/router'
import schemaGenerator from 'helpers/schema-generator'

const Head = ({
  siteTitle,
  siteDescription,
  siteUrl,
  pageTitle,
  themeColor,
  // social,
  location,
  metadata,
  canonical = siteUrl + (location.pathname || ''),
}) => {
  let pageTitleFull = siteTitle
  let description = siteDescription
  let img = `${siteUrl}/social.png`
  let width = 1024
  let height = 512

  if (metadata) {
    if (metadata.title.length > 0) {
      pageTitleFull = metadata.title + ' - ' + siteTitle
    }
    if (metadata.description.length > 0) {
      description = metadata.description
    }
    if (metadata.image.src.length > 0) {
      img = metadata.image.src
      width = metadata.image.width
      height = metadata.image.height
    }
  }

  return (
    <Helmet>
      <html lang="en" />

      <meta content="IE=edge" httpEquiv="X-UA-Compatible" />
      <meta
        content="width=device-width,initial-scale=1.0,user-scalable=yes"
        name="viewport"
      />

      <meta content={siteTitle} name="apple-mobile-web-app-title" />
      <meta content={pageTitleFull} property="og:title" />
      <meta content={pageTitleFull} name="twitter:title" />
      <title>{pageTitleFull}</title>

      <meta content={description} name="description" />
      <meta content={description} property="og:description" />
      <meta content={description} name="twitter:description" />

      <meta content="yes" name="apple-mobile-web-app-capable" />
      <meta
        content="black-translucent"
        name="apple-mobile-web-app-status-bar-style"
      />
      <meta content={themeColor} name="theme-color" />
      <meta content={siteTitle} name="application-name" />

      <meta content="website" property="og:type" />
      <meta content={siteTitle} property="og:site_name" />
      {/* <meta content={social.fbAppId} property="fb:app_id" /> */}
      <meta content="summary_large_image" name="twitter:card" />
      {/* <meta content={`@${social.twitter}`} name="twitter:site" />
      <meta content={`@${social.twitter}`} name="twitter:creator" /> */}
      <meta content={pageTitleFull} name="twitter:text:title" />
      <meta content={canonical} property="og:url" />
      <meta content={canonical} name="twitter:url" />
      <link rel="canonical" href={canonical} />

      <meta content={img} property="og:image" />
      <meta content={width} property="og:image:width" />
      <meta content={height} property="og:image:height" />
      <meta content={img} name="twitter:image" />
      <meta content={width} name="twitter:image:width" />
      <meta content={height} name="twitter:image:height" />
      <meta content={img} property="og:image" />
      <meta content={width} property="og:image:width" />
      <meta content={height} property="og:image:height" />

      <meta content={themeColor} name="msapplication-TileColor" />

      <script type="application/ld+json">
        {JSON.stringify(
          schemaGenerator({
            location,
            canonical,
            siteUrl,
            pageTitle,
            siteTitle,
            pageTitleFull,
          })
        )}
      </script>
    </Helmet>
  )
}

Head.propTypes = {
  siteTitle: PropTypes.string,
  siteDescription: PropTypes.string,
  siteUrl: PropTypes.string,
  themeColor: PropTypes.string,
  social: PropTypes.objectOf(PropTypes.string),
  imageUrl: PropTypes.string,
  canonical: PropTypes.string,
  pageTitle: PropTypes.string,
  location: PropTypes.object.isRequired,
  metadata: PropTypes.object,
}

const HeadWithQuery = (props) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            siteTitle
            siteDescription
            siteUrl
            themeColor
          }
        }
      }
    `}
    render={(data) => (
      <Location>
        {({ location }) => (
          <Head {...data.site.siteMetadata} {...props} location={location} />
        )}
      </Location>
    )}
  />
)

export default memo(HeadWithQuery)
